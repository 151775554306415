import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../utils/helpers/common';

export const SearchPageTitle = ({ title }) => {
  if (!title) return null;

  return <h1 className="search-page-title">{capitalizeFirstLetter(title)}</h1>;
};

SearchPageTitle.propTypes = {
  title: PropTypes.string
};
